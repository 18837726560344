import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import {Routes, RouterModule, Router} from "@angular/router";
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule }   from '@angular/forms';
import * as $ from "jquery";
import { CarouselModule } from 'ngx-owl-carousel-o';


import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RestaurantService }    from './restaurant.service';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { PizzaComponent } from './pizza/pizza.component';
import { PastaComponent } from './pasta/pasta.component';
import { TeaComponent } from './tea/tea.component';
import { FaqComponent } from './faq/faq.component';
import { PizzanavigationComponent } from './pizzanavigation/pizzanavigation.component';
import { PastanavigationComponent } from './pastanavigation/pastanavigation.component';
import { TeanavigationComponent } from './teanavigation/teanavigation.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ClientsComponent } from './clients/clients.component';
import { OrderComponent } from './order/order.component';
import { OrderingComponent } from './ordering/ordering.component';
import { PaniniComponent } from './panini/panini.component';
import { PanininavigationComponent } from './panininavigation/panininavigation.component';
// import { RewardsComponent } from './rewards/rewards.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    FooterComponent,
    PizzaComponent,
    PastaComponent,
    TeaComponent,
    FaqComponent,
    PizzanavigationComponent,
    PastanavigationComponent,
    TeanavigationComponent,
    AboutusComponent,
    ClientsComponent,
    OrderComponent,
    OrderingComponent,
    PaniniComponent,
    PanininavigationComponent,
    // RewardsComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUxfo6JhK218S18CPYWHMypgt4TiRU7gg',
      libraries: ['places']
   })
  ],
  providers: [RestaurantService],
  bootstrap: [AppComponent]
})
export class AppModule { }
