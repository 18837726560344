import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
  selector: 'app-panini',
  templateUrl: './panini.component.html',
  styleUrls: ['./panini.component.css']
})
export class PaniniComponent implements OnInit {

  menus: any;
  menutitle = 'PANINI';
  displaymenu = false;
  constructor(private Meta: Meta,
    private titleService: Title,) { }
  menucontent: any;

  ngOnInit() {
    this.titleService.setTitle("Boost your brain power with Priority Panini");
    $("#fb-link").attr('href', 'https://www.facebook.com/PriorityPanini/');
    this.menus = [{
      id: 1,
      image: 'panini_img_min.png',
      title: 'PANINI',
      pname: ''
    },

    {
      id: 2,
      image: 'sides_min.png',
      title: 'SIDES',
      pname: ''
    },
    {
      id: 3,
      image: 'drinks.png',
      title: 'DRINKS',
      pname: ''
    }];

    $(".ftgrouplogo").css('display', 'block');
    $(".ftpizzalogo").css('display', 'none');
  }


  openmenu(id): void {
    this.displaymenu = true;
    let elementList = document.getElementById("menuview");

    elementList.scrollIntoView(true);
    window.scrollBy(0, -70);
    if (id === 1) {
      this.menutitle = 'PANINI';
      this.menucontent = [
        {
          order: 1,
          index: 1,
          menutitle: '',
          title: 'Tuna & Cheese Melt',
          vimg: '',
          simg: '',
          description: 'Tuna Mayo, Cheddar Cheese',
        },
        {
          order: 1,
          index: 2,
          menutitle: '',
          vimg: '',
          simg: '',
          title: 'Ham & Cheese Melt',
          description: 'Honey Baked Ham, Emmental Cheese, Mustard Mayonnaise',
        },
        {
          order: 1,
          index: 3,
          menutitle: '',
          vimg: 'veg.png',
          simg: '',
          title: 'Italian Vegetarian',
          description: 'Sundried Tomato Pesto, Mozzarella, Fresh Spinach',
        },
        {
          order: 1,
          index: 3,
          menutitle: '',
          title: 'Chicken Katsu Curry',
          vimg: '',
          simg: '',
          description: 'Chicken Katsu Curry, Shredded Lettuce',
        },
        {
          order: 1,
          index: 4,
          menutitle: '',
          title: 'Fish Fillet',
          vimg: '',
          simg: '',
          description: 'Fish Fillet, Mustard Dill Mayonnaise, Pickled Radish, Shredded Lettuce',
        },
        {
          order: 1,
          index: 5,
          menutitle: '',
          title: 'Priority Meatball Sub',
          vimg: '',
          simg: '',
          description: 'Priority Jumbo Meatballs, Tomato Sauce, Cheddar Cheese, Onion Rings, Shredded Lettuce',
        },
        {
          order: 1,
          index: 6,
          menutitle: '',
          title: 'Meat Lover',
          vimg: 'spicy.png',
          simg: '',
          description: 'Salami, Honey Baked Ham, Emmental Cheese, Caramelised Onions, Chilli Aioli',
        },
        {
          order: 1,
          index: 7,
          menutitle: '',
          title: 'Peanut Butter Jelly',
          vimg: 'veg.png',
          simg: '',
          description: 'Peanut Butter, Jelly, Cream Cheese',
        },




      ];

    } else if (id === 2) {
      this.menutitle = 'Sides';
      this.menucontent = [{
        order: 1,
        index: 1,
        menutitle: 'Sides',
        title: 'Fries',
        vimg: 'veg.png',
        simg: '',
        description: 'Shoestring fries with choice of sauce.',
      },
      {
        order: 1,
        index: 2,
        title: "Meatballs",
        vimg: '',
        simg: '',
        description: 'Beef meatballs with choice of sauce.',
      },
      {
        order: 1,
        index: 3,
        vimg: '',
        simg: 'spicy.png',
        title: 'Popcorn Chicken',
        description: 'Bite sized pieces of tender chicken coated in a spicy, crispy batter with choice of dip.',
      },
      ];
    } else if (id === 3) {
      this.menutitle = 'Drinks';
      this.menucontent = [{
        order: 1,
        index: 1,
        menutitle: 'Drinks',
        title: 'Sakura',
        vimg: '',
        simg: '',
        description: 'Cherry blossom green tea with apple juice, aloe vera & rose petals.',
      },
      {
        order: 1,
        index: 2,
        title: "Luna",
        vimg: '',
        simg: '',
        description: 'Jasmine white tea with grapefruit juice & osmanthus flowers.',
      },


      ];
    }

  };


}

