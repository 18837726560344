import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pizzanavigation',
  templateUrl: './pizzanavigation.component.html',
  styleUrls: ['./pizzanavigation.component.css']
})
export class PizzanavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
