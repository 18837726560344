import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private Meta:Meta,
		private titleService: Title,) { }

  ngOnInit() {
  this.titleService.setTitle("Who on earth is Priority? We operate like any other restaurant, but we also connect with you online. Add us on Facebook, Instagram or TikTok, homie!" );
  }

}
