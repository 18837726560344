import { Component, OnInit,OnDestroy,ViewEncapsulation } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Title }     from '@angular/platform-browser';


@Component({
  selector: 'clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class ClientsComponent implements OnInit {

customOptions: any = {
items : 3,
autoplay:true,
    autoplayTimeout: 3000,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
  responsiveClass:true,
    responsive: {
      0: {
        items: 3
      },
      950: {
        items: 4
      },
      1400:{
        items:4,
        loop:true
      }
     
    },
    nav: false
  };

 constructor(
    private sanitizer: DomSanitizer,
    
   
    ) {
      
    }

  m_state: string = 'inactive';
  name;path;bkurl;url;
 ngOnInit(): void {

  } 
    
  

}
