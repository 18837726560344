import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pizza',
  templateUrl: './pizza.component.html',
  styleUrls: ['./pizza.component.css']
})
export class PizzaComponent implements OnInit {
	menus :any;
  menutitle ='12" PIZZA';
	displaymenu = false;
  constructor(private Meta:Meta,
        private titleService: Title,) { }
  menucontent:any;

  ngOnInit() {
  this.titleService.setTitle( "Tired of basic pizza flavours? Break the rules with chieck and fries or Mac & cheese on thin crust  pizza. Available on Deliveroo, Foodpanda and Grabfood!" );

   $("#fb-link").attr('href','https://www.facebook.com/PriorityPizzaSG/');
  this.menus = [ {
            id: 1,
            image :'pizza2_min.png',
            title:'12" PIZZA',
            pname: ''
        },
        
        {
            id: 5,
            image :'sides2_min.png',
            title:'SIDES',
            pname: ''
        },
        {
            id: 6,
            image :'drinks2.png',
            title:'DRINKS',
            pname: ''
        }];

           $(".ftgrouplogo").css('display','block');
    $(".ftpizzalogo").css('display','none');
  }


	openmenu(id): void {
	   this.displaymenu = true;
      let  elementList = document.getElementById("menuview");

      elementList.scrollIntoView(true);
            window.scrollBy(0,-70);
       if(id === 1){
       this.menutitle = '12" PIZZA';
        this.menucontent = [ 
        // {
        //     order:1,
        //     index:1,
        //     menutitle :'',
        //     title:'Paneer Dungeon',
        //      vimg:'veg.png',
        //     simg:'',
        //     description: 'Spinach, cottage cheese, garlic yoghurt, onions, fresh tomatoes',
        // },
        {
            order:1,
            index:2,
            menutitle :'',
            vimg:'',
            simg:'',
            title:'Shy Guy`s Chilli Fries',
            description: 'Golden fries, spiced minced beef, cheddar, red onions, tomato sauc',
        },
        {
            order:1,
            index:3,
            menutitle :'',
            vimg:'',
            simg:'',
            title:'Pac-Pan`s Pepperoni',
            description: 'Pepperoni (pork), chilli, tomato sauce, mozzarella',
        },
        {
            order:1,
            index:3,
            menutitle :'',
            title:'Pineapple Quest',
             vimg:'',
            simg:'',
            description: 'Ham, bacon, pineapple, BBQ tomato sauce, mozzarella',
        },
        {
            order:2,
            index:3,
            menutitle :'',
             vimg:'veg.png',
            simg:'',
            title:'Cheesy RPG',
            description: 'Cheddar, tomato sauce, mozzarella',
        },

        
         {
            order:2,
            index:3,
            menutitle :'',
             vimg:'',
            simg:'',
            title:'64-bit Kebab',
            description: 'Spiced chicken, garlic yoghurt, onions, rocket leaves, fresh tomatoe',
        },
         {
            order:2,
            index:3,
            menutitle :'',
             vimg:'veg.png',
            simg:'',
            title:'Truffle Mac Attack',
            description: 'Truffle mac & cheese, cheddar, tomato sauce, mozzarella.',
        },
         {
            order:2,
            index:3,
            menutitle :'',
             vimg:'',
            simg:'',
            title:'Curry Curry Revolution',
            description: 'Golden fries, jap curry chicken, cheddar, tomato sauce, mozzarella',
        },
        
         
        ];

       }else if(id ===2){
         this.menutitle = 'Mac & Cheese';
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'Mac & Cheese',
            title:'Diamond in the Truff',
             vimg:'',
            simg:'',
            description: 'Truffle Mac & Cheese',
        },
        {
            order:1,
             index:2,
            menutitle :'',
            title:'Pig Mac',
             vimg:'',
            simg:'',
            description: 'Slow Cooker BBQ Pulled Pork Mac & Cheese',
        },
        {
           order:2,
            index:3,
            menutitle :'',
            title:'Mac-khani',
             vimg:'',
            simg:'',
            description: 'Butter Chicken Mac & Cheese',
        },
        ];

       
        }else if(id ===3){
         this.menutitle = 'Meatballs';
        this.menucontent = [ {
            order:1,
            index:1,
             menutitle :'Meatballs',
            title:'Swedish Meatball Mafia',
             vimg:'',
            simg:'',
            description: 'Swedish Beef Meatballs & Mashed Potatoes',
        },
        {
            order:1,
            index:2,
             menutitle :'',
            title:"A Fat Greek's Creed: Odyssey",
             vimg:'',
            simg:'',
            description: 'Beef Meatballs, Garlic Yogurt Sauce & Mashed Potatoes',
        },
        {
            order:1,
            index:3,
             menutitle :'',
            title:'Godzilla Meatballs',
             vimg:'',
            simg:'',
            description: 'Japanese Curry Beef Meatballs & Mashed Potatoes',
        },
      
        ];
      }else if(id ===5){
         this.menutitle = 'Sides';
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'Sides',
            title:'Fries',
             vimg:'veg.png',
            simg:'',
            description: 'Shoestring fries with choice of sauce: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
        },
        {
            order:1,
            index:2,
            title:"Meatballs",
             vimg:'',
            simg:'',
            description: 'Beef meatballs with choice of sauce: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
        },
        {
            order:1,
            index:3,
            vimg:'',
            simg:'spicy.png',
            title:'Popcorn Chicken',
            description: 'Bite sized pieces of tender chicken coated in a spicy, crispy batter with choice of dip: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
        },
        ];
      }
      else if(id ===4){
         this.menutitle = 'Fries';
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'Fries',
            title:'Sambal Cheese',
            vimg:'veg.png',
            simg:'',
            description: '',
        },
        {
            order:1,
            index:2,
            title:"Mentaiko Spring Onion",
             vimg:'',
            simg:'',
            description: '',
        },
        {
            order:1,
            index:3,
            title:'Swedish Curry',
             vimg:'',
            simg:'',
            description: '',
        },
        {
            order:1,
            index:3,
            title:'Rojak Peanut',
             vimg:'',
            simg:'',
            description: '',
        },
        {
            order:1,
            index:3,
            title:'Cheesy Chili Crab',
             vimg:'',
            simg:'',
            description: '',
        },
      
        ];
      }
       else if(id ===6){
         this.menutitle = 'Drinks';
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'Drinks',
            title:'Sakura',
            vimg:'',
            simg:'',
            description: 'Cherry blossom green tea with apple juice, aloe vera & rose petals',
        },
        {
            order:1,
            index:2,
            title:"Luna",
            vimg:'',
            simg:'',
            description: 'Jasmine white tea with grapefruit juice & osmanthus flowers',
        },
        
      
        ];
      }

	};


}
