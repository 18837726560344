import { Component,  ViewChild, ViewEncapsulation, AfterViewInit,OnInit } from '@angular/core';
import * as $ from 'jquery';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pasta',
  templateUrl: './pasta.component.html',
  styleUrls: ['./pasta.component.css'],
   encapsulation: ViewEncapsulation.None
})
export class PastaComponent implements OnInit {
displaymenu = false;
displaycards = true;
displayorder = false;
displaydish = false;
cardtitle ="Pasta";
menucontent:any;
cardfooter = "PLAY A CARD. ANY CARD ";
customOptions: any = {
items : 1,
autoplay:true,
    autoplayTimeout: 7000,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth:true,
  responsiveClass:true,

    responsive: {
      0: {
        items: 1
      },
      950: {
        items: 1
      },
      1400:{
        items:1,
        loop:true
      }
     
    },
    nav: false
  };

  constructor(private modalService: NgbModal,private Meta:Meta,
        private titleService: Title,) { }

  ngOnInit() {
  this.titleService.setTitle( "Satisfy your pasta cravings with Priority Pasta. Our pasta comes in regular spicy, super spicy and crazy spicy! Available on Deliveroo, Foodpanda and Grabfood." );
    $(".ftgrouplogo").css('display','block');
    $(".ftpastalogo").css('display','none');
    $("#fb-link").attr('href','https://www.facebook.com/PriorityPastaSG');
    this.cardfooter = "PLAY A CARD. ANY CARD ";
  }
   openmenu(id) {

      this.displaymenu = true;
       this.displaydish = true;
      this.displaycards = false;
      this.cardfooter = "PICK A CARD. ANY CARD. ";
       if(id === 1){
       this.cardtitle ="Pasta";
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Wild Card Mala Chicken',
            image:'pasta-tomoto1.png',
            description: 'Mala chicken, garlic, olive oil, chili, spring onion. Choose from regular, super spicy and crazy spicy!',
            vimg:'',
            simg:'spicy.png',
        },
        {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Salmon Mentaiko Stack',
            image:'pasta-salmon_min.png',
            description: 'Mentaiko, salmon, cream sauce, spring onions.',
            vimg:'',
            simg:'',
        },
        {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Ace of Bolognese',
            image:'pasta-bolognese_min.png',
            description: 'Minced beef, tomato sauce, parmesan.',
            vimg:'',
            simg:'',
        },
         {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Carbonara Grand Slam',
            image:'carbonara_min.png',
            description: 'Bacon, ham, egg yolk-cream sauce, parmesan.',
            vimg:'',
            simg:'',
        },
         {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'King of Spicy Tomato',
            image:'pasta-tomoto1.png',
            description: 'Spicy tomato garlic sauce. Choose from regular, super spicy and crazy spicy!',
             vimg:'veg.png',
            simg:'spicy.png',
        },
        {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Queen of Aglio-Olio',
            image:'queen_min.png',
            description: 'Chunky garlic, olive oil, cherry tomatoes, chili. Choose from regular, super spicy and crazy spicy!',
             vimg:'veg.png',
            simg:'spicy.png',
        },
         
         {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Sleight of Tom Yum',
            image:'pasta-prawn_min.png',
            description: 'Tom yum prawns, chilli, cherry tomatoes, garlic, onions, lime, coriander. Choose from regular, super spicy and crazy spicy!',
             vimg:'',
            simg:'spicy.png',
        },
        {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Taco-cchi Blanche',
            image:'pasta-taco_min.png',
            description: 'Taco minced beef, egg yolk-cream sauce, parmesan.',
             vimg:'',
             simg:'',
        },

        
        ]
      }
      else if(id === 2){
        this.cardtitle ="Sides";
        this.menucontent = [ 
        {
            order:1,
            index:1,
            menutitle :'Sides',
            title:'Fries ',
            image:'sides2_min.png',
            description: 'Shoestring fries with choice of sauce: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
             vimg:'veg.png',
            simg:'',
        },
        {
            order:1,
            index:1,
            menutitle :'Sides',
            title:'Meatballs',
            image:'meatballnew_min.png',
            description: 'Beef meatballs with choice of sauce: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
             vimg:'',
            simg:'',
        },
        {
            order:1,
            index:1,
            menutitle :'Sides',
            title:'Popcorn Chicken',
            image:'popcornnew_min.png',
            description: 'Bite sized pieces of tender chicken coated in a spicy, crispy batter with choice of dip: Greek Garlic Yoghurt, Mentaiko, Japanese Curry or Swedish Gravy.',
             vimg:'',
            simg:'spicy.png',
        },

        ]
      }
 
      else if(id === 4){
       this.cardtitle ="Drinks";
        this.menucontent = [ {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Sakura',
            image:'sakura_min.png',
            description: 'Cherry blossom green tea with apple juice, aloe vera & rose petals.',
             vimg:'',
            simg:'',
        },
        {
            order:1,
            index:1,
            menutitle :'PASTA',
            title:'Luna',
            image:'luna_min.png',
            description: 'Jasmine white tea with grapefruit juice & osmanthus flowers.',
            vimg:'',
            simg:'',
        }
        
        ]
      }
 
  }
  close(){
    this.displaymenu = false;
    this.displaydish = false;
    this.displaycards = true;
    this.cardfooter = "PLAY A CARD. ANY CARD ";
  }

  

}
