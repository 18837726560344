import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { Title }     from '@angular/platform-browser';
import * as $ from 'jquery';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-tea',
  templateUrl: './tea.component.html',
  styleUrls: ['./tea.component.css']
})
export class TeaComponent implements OnInit {
	teapopup = false;
	teapopup1 = true;
  constructor(private modalService: NgbModal,) { }

  ngOnInit() {
   $(".ftgrouplogo").css('display','block');
    $(".fttealogo").css('display','none');
  }

  orderpopup(id): void {
alert(id);
  	this.teapopup = true;
  	this.teapopup1 = false;
  	
  }

}
