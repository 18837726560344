import { Component, OnInit } from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  oourl;
  url;
  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // this.oourl = "https://www.restaurants.sg/modules/online_orderingV2/dist/home/0?restaurant=SG_SG_R_PriorityClubDEK&orderid=2060767486&token=VfnlVdKe&";
    this.oourl = "https://www.restaurants.sg/modules/crs/online_order/index.php?data=jINgkPmQ3YJY_NKX.7xVAh9KnOXgcUMix1GsCePQG6BfvpLfi6BKFXizau.k6kFIb9dqq5R32tYLOiIKvTJxuQK";


    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.oourl);
  }

}
