import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { PizzaComponent } from './pizza/pizza.component';
import { PastaComponent } from './pasta/pasta.component';
import { TeaComponent } from './tea/tea.component';
import { FaqComponent } from './faq/faq.component';
import { OrderComponent } from './order/order.component';
import { PaniniComponent } from './panini/panini.component';
// import { RewardsComponent} from './rewards/rewards.component'

const APP_ROUTES: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},  
  {path: 'prioritypizza', component: PizzaComponent}, 
  {path: 'prioritypasta', component: PastaComponent},
  {path: 'prioritytea', component: TeaComponent},  
  {path: 'faq', component: FaqComponent}, 
  {path: 'order', component: OrderComponent}, 
  {path: 'prioritypanini', component: PaniniComponent},
  // {path: 'rewards', component: RewardsComponent},

];



@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]

})

export class AppRoutingModule { }
