import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';


import { Restaurant } from './restaurant';
import { RestaurantMenu } from './restaurant-menu';
import { RestaurantEvent } from './restaurant-event';


@Injectable()
export class RestaurantService {

    name: string = 'SG_SG_R_Upstairs';
    lat: number = 1.293396;
  	lng: number = 103.853462;
	url :string ="https://www.restaurants.sg/";
  	devurl :string ="https://www.weeloy.com/";
	private m_restaurant_url = 'https://www.weeloy.com/api/restaurantfullinfo';
	private m_restaurant_events_url = 'https://dev.weeloy.com/api/event/widget';

	private m_headers = new Headers({'Content-Type': 'application/json'});

	constructor(private http: Http) {}

	getRestaurant(id: string): Promise<Restaurant> {
		const url = `${this.m_restaurant_url}/${id}`;
		return this.http.get(url)
			.toPromise()
		    .then(response => response.json().data.restaurantinfo as Restaurant)
		    .catch(this.handleError);

	}

	getRestaurantMenu(id: string): Promise<RestaurantMenu[]> {
		const url = `${this.m_restaurant_url}/${id}`;
		console.log("RestaurantService getRestaurantMenu url: " + url)
		return this.http.get(url)
			.toPromise()
		    .then(response => response.json().data.menu as RestaurantMenu[])
		    .catch(this.handleError);
	}
	



	getRestaurantEvents(id: string): Promise<RestaurantEvent[]> {
		const url = `${this.m_restaurant_events_url}/${id}`;
		console.log("RestaurantService getRestaurantEvents url: " + url)
		return this.http.get(url)
			.toPromise()
		    .then(response => response.json().data.event as RestaurantEvent[])
		    .catch(this.handleError);

	}

	private handleError(error: any): Promise<any> {
		console.error('an error occured', error);
		return Promise.reject(error.message || error);
	}
}