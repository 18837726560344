import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute ,Router} from '@angular/router';
import {Http} from '@angular/http';
import { HttpModule } from '@angular/http';
import {BrowserModule} from '@angular/platform-browser';
import { Headers, RequestOptions } from '@angular/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  subject = '';
  name ='';
  email ='';
  comment ='';

  constructor(private Meta:Meta,
    private titleService: Title,
    private route: ActivatedRoute,
      private router:Router,
      private http:Http,) { }

  ngOnInit() {

 
    this.route.fragment.subscribe((fragment: string) => {
        console.log("My hash fragment is here => ", fragment);
        if(typeof fragment ==='string' &&  fragment ==='contact'){
           let elmnt = document.getElementById("contact");
          // $(".navbar-collapse").collapse('hide');
          elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });

        }
       console.log("fragment",typeof fragment);
    })
    this.titleService.setTitle( "Taste and innovation are our Priority. Break the rules with fries on thin crust pizza, mala chicken pasta or go crazy with saucy fries. Available for delivery!" );
  $(".facebook").css('display','none');

 
  }

SubmitNewsletter(): void {
      let headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        let options = new RequestOptions({ headers: headers });
        this.http
        .post('https://dev.weeloy.com/modules/contact_widget/checkemail.php', 
          {name: this.name, email: this.email, mobile: '', location: '', comment: this.comment, subject: 'The Priority club', restemail: 'dod.tpc@thepriorityclub.com'},options).subscribe();
      alert("Request Send");
      this.email = '';
      this.name = '';
      this.subject = '';
      this.comment = '';
}

}
